import { CalmError } from '@/utils/apiRequest/errors';

import { useFeatureFlags, FeatureFlagNames } from './api/useFeatureFlags';

interface FlagLoadingState {
	loading: boolean;
	error: Error | CalmError | undefined;
}

type Response = [boolean, FlagLoadingState];

export const useShouldShowSelfServeAch = (): Response => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(FeatureFlagNames.B2B_SELF_SERVE_ADD_ACH);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const selfServeAchFlag =
		flagValues && (flagValues[FeatureFlagNames.B2B_SELF_SERVE_ADD_ACH] as boolean | undefined);
	const shouldShowSelfServeAch = Boolean(flagsLoaded && selfServeAchFlag);

	return [shouldShowSelfServeAch, { loading: flagLoading, error: flagError }];
};
