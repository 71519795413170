import { SetStateAction } from 'react';

import { stringFromModelValue } from '@calm-web/use-form';

import { JSONObject } from '@/types/json';

import { CompanyInfoFormProps } from './Company/types';
import { TeamsInfoFormProps } from './Info/types';
import { CoveredLivesCount } from './types';

const converter = require('number-to-words');

export type ValidActions = 'submit' | 'blur';

export const FORM_LOG_STRING = 'Teams : Form';

export const calculatingSelectedContractedLives = (coveredLives: number): string => {
	const ranges: { min: number; max: number; label: string }[] = [
		{ min: 0, max: 4, label: '0' },
		{ min: 5, max: 100, label: '5-100' },
		{ min: 101, max: 300, label: '101-300' },
		{ min: 301, max: 500, label: '301-500' },
	];

	const selectedRange = ranges.find(range => coveredLives >= range.min && coveredLives <= range.max);
	return selectedRange ? selectedRange.label : '501+';
};

export const performTeamsFormValidation = ({
	action,
	formProps,
	setError,
	logEvent,
	location,
	coveredLives,
}: {
	action: ValidActions;
	formProps: TeamsInfoFormProps | CompanyInfoFormProps;
	setError: (value: SetStateAction<string | null>) => void;
	logEvent: (eventName: string, eventProps?: JSONObject) => void;
	location: string;
	coveredLives?: CoveredLivesCount;
}): boolean => {
	if (!formProps.validation.isValid) {
		const errors: string[] = [];
		const numberOfInvalidFields = Object.values(formProps.validation.fields).reduce((count, item) => {
			if (item?.isValid === false) {
				errors.push(...item.errors);
				count++;
			}
			return count;
		}, 0);

		if (action === 'submit') {
			setError(converter.toWords(numberOfInvalidFields));
		}

		const companySize =
			'companySize' in formProps.model ? stringFromModelValue(formProps.model.companySize) : '';

		logEvent(FORM_LOG_STRING, {
			location,
			status: 'error',
			action,
			errors,
			variant: '7-16-24',
			selectedCoveredLives: coveredLives ?? '',
			selectedContractedLives: calculatingSelectedContractedLives(coveredLives ?? 0),
			companySize: companySize ?? '',
		});

		return false;
	}

	return true;
};

export const SPEAK_WITH_SPECIALIST_LINK = 'https://business.calm.com/meet-with-a-specialist/';
