/* eslint-disable react/no-danger */

import { FC, useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { logCalmEventProperties } from '@/utils/analytics/calm';

const InitialPageAnalytics: FC = () => {
	const location = useLocation();
	const pathname = location?.pathname;
	const { logEvent } = useAnalytics();
	const { partnerId } = useParams() as { partnerId?: string };

	useLayoutEffect(() => {
		// Log base event props - like the device id
		logCalmEventProperties();
		// Log the base page view
		const pageName = pathname.replace('/', '').replace(`${partnerId}/`, '');
		logEvent('Page : Viewed', {
			page_name: pageName,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return <></>;
};

export default InitialPageAnalytics;
