import { useEffect, useState } from 'react';

import { CalmTheme } from '@calm-web/design-system';

export function useIsMobile(): [isMobile: boolean] {
	const [isMobile, setIsMobile] = useState(false);

	function updateMobile(): void {
		if (window.innerWidth <= CalmTheme.breakpoints.desktop) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}

	useEffect(() => {
		updateMobile();
		window.addEventListener('resize', updateMobile);
		return () => {
			window.removeEventListener('resize', updateMobile);
		};
	}, []);

	return [isMobile];
}
