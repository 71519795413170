import styled from 'styled-components';

import { FontSizes, FontWeights, Text, units } from '@calm-web/design-system';

export const Container = styled.div``;

export const Header = styled(Text).attrs({
	el: 'h3',
	weight: FontWeights.Medium,
	size: FontSizes.lg,
	color: 'black1',
	noMargin: true,
})`
	margin-top: ${units(3)};
	margin-bottom: ${units(3)};
`;

export const StyledHr = styled.hr`
	opacity: 0.2;
	margin-top: ${units(5)};
`;
