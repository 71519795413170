import { JSONObject } from '@/types/json';
import { isCalmError } from '@/utils/apiRequest/errors';

export function formatError(error: unknown): JSONObject {
	if (!isCalmError(error)) {
		// Always return an object so we can use spread operator on it
		return {};
	}
	const err = error?.data?.error || error?.data || error;
	if (typeof err !== 'object') return {};
	return {
		error_did_timeout: err.didTimeout || '',
		error_calm_code: err.code || err.calmCode || '',
		error_status_code: error.status,
		error_message: err.message || '',
		error_info: (err.info || '').toString(),
		error_calm_message: err.calmMessage || '',
	};
}
