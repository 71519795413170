import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PaymentInfo } from '@/components/pages/Plan/PlanDetails/types';
import { CompanySize, CompanySize71624 } from '@/components/pages/Teams/Company/types';
import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';
import { formatError } from '@/utils/analytics/dataFormatting';
import { CalmError, isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { useAnalytics } from '../analytics/useAnalytics';
import { useStripeHelp } from '../useStripeHelp';
import { usePaymentInfo } from './usePaymentInfo';

interface SelfServePurchaseRequest {
	email: string;
	first_name: string;
	last_name: string;
	company_name: string;
	company_size: CompanySize | CompanySize71624 | '';
	title: string;
	covered_lives: number;
	coupon?: string;
	stripe_confirmation_token_id?: string;
}

interface SelfServePurchaseType {
	(body: SelfServePurchaseRequest): Promise<{
		data: { partner: Partner; redirectUrl?: string; status?: string; clientSecret?: string };
	}>;
}

interface HandleError {
	(error: Error | CalmError | string): string;
}

interface UpdateBody {
	stripe_confirmation_token_id?: string;
}

interface SubmitPaymentUpdateType {
	(partnerId: string, body: UpdateBody): Promise<{ data: PaymentInfo }>;
}

type HandlePaymentChange = (opts: { setShowChangeModal: Dispatch<SetStateAction<boolean>> }) => Promise<void>;

export interface PurchaseReturnType {
	submitSelfServePurchase: SelfServePurchaseType;
	handlePurchaseError: HandleError;
	isUpdatingPayment: boolean;
	handlePaymentChange: HandlePaymentChange;
}

const TEAMS_UPDATE_PAYMENT_INSTRUMENT_LOG_PREFIX = 'Teams : Update Payment Instrument';

export function usePurchase(): PurchaseReturnType {
	const { partnerId } = useParams();
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [isUpdatingPayment, setIsUpdatingPayment] = useState(false);
	const { createConfirmationToken } = useStripeHelp();
	const { mutate: mutatePaymentInfo } = usePaymentInfo();
	const { logEvent } = useAnalytics();

	const handlePurchaseError: HandleError = useCallback(error => {
		const timeout = isCalmError(error) && String(error?.status) === '408';
		if (timeout) {
			return 'Request timed out. Please check your internet connection or try again later.';
		}

		const errorCode = (() => {
			if (typeof error === 'string') {
				return error;
			}
			if (isCalmError(error)) {
				return error?.data?.error?.code;
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return (error as any)?.code || error?.message;
		})();

		switch (errorCode) {
			case 'missing_card_info':
				return 'Error creating Stripe card info. Please try again.';
			case 'b2b_cannot_find_price':
				return 'Error: missing pricing info';
			case 'partner_portal_user_already_exist':
				return 'Error: email is already in use. Please sign up with a different email.';
			case 'card_declined':
				return 'Error: card was declined. Please verify the card info and try again.';
			case 'expired_card':
				return 'Error: card is expired. Please verify the card info or try a different card.';
			case 'incorrect_cvc':
				return 'Error: incorrect security code. Please verify the security code printed on your credit card and try again.';
			case 'insufficient_funds':
				return 'Error: insufficient funds. Please try a different card.';
			case 'processing_error':
				return 'Error: temporary processing outage. Please try again.';
			default:
				return 'Unable to process payment at this time.';
		}
	}, []);

	const submitSelfServePurchase: SelfServePurchaseType = useCallback(
		async body => {
			const result = await apiRequest({
				endpoint: 'b2b/selfserve/signup',
				method: 'POST',
				body,
			});
			// Handle nested or not-nested partner
			return {
				data: {
					partner: result.data?.partner ?? result.data,
					redirectUrl: result.data?.redirectUrl,
				},
			};
		},
		[apiRequest],
	);

	const submitPaymentUpdate: SubmitPaymentUpdateType = useCallback(
		async (partnerId, body) => {
			return apiRequest({
				endpoint: `b2b/partners/${partnerId}/payment-instrument`,
				method: 'PATCH',
				body,
			});
		},
		[apiRequest],
	);

	const handlePaymentChange: HandlePaymentChange = useCallback(
		async ({ setShowChangeModal }) => {
			let paymentMethodType: string | undefined;
			try {
				setIsUpdatingPayment(true);

				const confirmationToken = await createConfirmationToken();
				if (!confirmationToken) {
					dispatch(
						setBannerMessage({
							message: 'Failed to create confirmation token',
							isError: true,
							flash: true,
						}),
					);
					return;
				}
				paymentMethodType = confirmationToken.payment_method_preview.type;
				logEvent(`${TEAMS_UPDATE_PAYMENT_INSTRUMENT_LOG_PREFIX} : Initiated`, {
					...(paymentMethodType ? { payment_method_type: paymentMethodType } : {}),
				});
				await submitPaymentUpdate(partnerId, {
					stripe_confirmation_token_id: confirmationToken.id,
				});
				await mutatePaymentInfo();
				setShowChangeModal(false);
				dispatch(
					setBannerMessage({
						message: 'Successfully updated Payment info!',
						isError: false,
						flash: true,
					}),
				);
				logEvent(`${TEAMS_UPDATE_PAYMENT_INSTRUMENT_LOG_PREFIX} : Success`, {
					...(paymentMethodType ? { payment_method_type: paymentMethodType } : {}),
				});
			} catch (e) {
				logEvent(`${TEAMS_UPDATE_PAYMENT_INSTRUMENT_LOG_PREFIX} : Error`, {
					...formatError(e),
					...(paymentMethodType ? { payment_method_type: paymentMethodType } : {}),
				});
				calmLogger.error('Teams : Error updating payment info', { partnerId }, e);
				dispatch(
					setBannerMessage({
						message: handlePurchaseError(e as Error | CalmError),
						isError: true,
						flash: true,
					}),
				);
				throw e;
			} finally {
				setIsUpdatingPayment(false);
			}
		},
		[
			handlePurchaseError,
			partnerId,
			createConfirmationToken,
			submitPaymentUpdate,
			dispatch,
			mutatePaymentInfo,
			logEvent,
		],
	);

	return {
		submitSelfServePurchase,
		handlePurchaseError,
		handlePaymentChange,
		isUpdatingPayment,
	};
}
