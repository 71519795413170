import qs from 'query-string';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { useLocation } from '@/hooks/useLocation';
import { setBannerMessage } from '@/store/actions';

export const MAX_COVERED_LIVES_INCREASE = 500;
export const MIN_COVERED_LIVES = 5;
export const MIN_COVERED_LIVES_TO_BOOK_CONSULTANT = 300;

interface DiscountedPricingDetails {
	totalCost?: number;
	savingsPerUser?: number;
	pricePerCoveredLife?: number;
	totalSavings?: number;
	fullPricePerUser?: number;
	loading: boolean;
	error: unknown;
	coupon?: {
		id: string;
		duration: string;
		percent_off: number;
	};
	originalTieredPrice?: number;
}

const ORIGINAL_PRICE = 7000;

const PLAN = 'b2b_selfserve_1y';

export default function useDiscountedPricing(coveredLives: number): DiscountedPricingDetails {
	const { search } = useLocation();
	const query = qs.parse(search);
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const maxCoveredLives = MAX_COVERED_LIVES_INCREASE;
	const requestQuery = {
		coupon: query.coupon,
		quantity: coveredLives,
		plan: PLAN,
		max_covered_lives: maxCoveredLives,
	};
	const { data, error, isLoading } = useSWR(
		`stripe-price-catalog/price?${qs.stringify(requestQuery)}`,
		async endpoint => {
			try {
				if (coveredLives > maxCoveredLives || coveredLives < MIN_COVERED_LIVES) {
					return {
						totalCost: undefined,
						savingsPerUser: undefined,
						pricePerCoveredLife: undefined,
						totalSavings: undefined,
						fullPricePerUser: undefined,
						loading: false,
					};
				}
				const response = await apiRequest({ endpoint });
				if (!response.data) {
					throw new Error('No prices found');
				}
				return response.data;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: 'Error retrieving prices',
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
	);

	if (coveredLives > maxCoveredLives || coveredLives < MIN_COVERED_LIVES) {
		return {
			...data,
			loading: false,
			error,
		};
	}

	if (error) {
		return {
			error,
			loading: false,
		};
	}

	if (!data) {
		return {
			error: null,
			loading: isLoading,
		};
	}

	const pricePerCoveredLife = coveredLives ? data?.price / coveredLives : 0;
	const savingsPerUser = coveredLives ? (data?.original_price - data?.price) / coveredLives : 0;
	const totalCost = data?.price;
	const totalSavings = savingsPerUser * coveredLives;
	const coupon = data?.coupon;
	const originalTieredPrice = data?.original_tiered_price;

	return {
		totalCost,
		savingsPerUser,
		pricePerCoveredLife,
		totalSavings,
		fullPricePerUser: ORIGINAL_PRICE,
		loading: isLoading,
		error,
		coupon,
		originalTieredPrice,
	};
}
