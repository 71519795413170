import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ConfirmationToken } from '@stripe/stripe-js';
import { useCallback } from 'react';

import { calmLogger } from '@/utils/calmLogger';

type UseStripeHelp = () => {
	createConfirmationToken: () => Promise<ConfirmationToken | undefined>;
};

export const useStripeHelp: UseStripeHelp = () => {
	const stripe = useStripe();
	const elements = useElements();

	const createConfirmationToken = useCallback(async () => {
		try {
			if (!stripe || !elements || !elements.getElement(PaymentElement)) {
				return;
			}
			await elements.submit();
			const res = await stripe.createConfirmationToken({ elements });
			if (!res.confirmationToken) {
				throw new Error('Confirmation token missing from response');
			}
			return res.confirmationToken;
		} catch (err) {
			calmLogger.warn('Failed to create confirmation token', err);
			return;
		}
	}, [stripe, elements]);

	return {
		createConfirmationToken,
	};
};
